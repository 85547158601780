.introIMAGE2{
    width: 100%;
    background: url("https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
    height: 60vh; /*Makes the heading bigger*/
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    
}

.introIMAGE2::before{
    
    background-size: cover;
    
    background-position:center top;
    
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
   
    
}
.introIMAGE2::after{
    content:"";
    width: 100%;
    height: 60vh;
    position: absolute;
    background-size: cover;
    background-color: #919191;
    opacity: 0.3;
    top: 0;
    left: 0;
}
.header h1{
    font-size: 4rem;
    color:  rgba(0, 0, 0, 0.85);
}
.header p{
    font-size: 2rem;
    text-align: center;
    color: rgb(0, 0, 0);
}
@media screen and (max-width: 640px) {
    .header h1{
        font-size: 2rem;
        color: rgb(15, 15, 15);
        background-size: cover;
    }
    .header p{
        font-size: 1rem;
        text-align: center;
        color: rgb(8, 8, 8);
    }
}
.header{
    width: 100%;
    height: 100%;
    display:flex; /*Makes paragraph and header text at the same*/
    flex-direction: column; /*Makes flex column*/
    align-items: center; /* Center of header*/
    justify-content: center; /*center of the header*/
    transform: translate(0%, -200%);
    
    

}

