

.small-btn {
    width: 40px;
    height: 40px;
    margin-right: 7px;
    margin-left: 7px;
}

.small-highlight{
    width: 40px;
    height: 40px;
    margin-right: 7px;
    margin-left: 7px;
    transform: scale(1.3);
    transition: 0.8s;
    justify-content: space-between;
}


.WorkHeading{
    text-align: center;
    color: rgb(0, 0, 0);
    padding: 4rem 0 2rem 0;
}
.WorkContainer{
    max-width: 1140px;
    margin: auto;
    display: grid;
    /*Grid Adjustment*/
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.WorkCard{
    background: rgb(102, 101, 101);
    padding: 1.2rem 1rem;

}

.WorkCard img{
    width: 290px;
    height: 200px;
    object-fit: cover;
}

.WorkTitle{
    color: rgb(14, 13, 13);
    padding: 1rem;
    text-align: center;

}
/* .icons{
    display: flex;
    justify-content: space-between;
    
} */
.WorkDetails p{
    padding-bottom: 1rem;
    font-size: 1rem;
    text-align: justify;

}

.WorkButtons{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.WorkButtons .button1{
    padding: 0.5rem 1rem;
}

.button2{
    padding: 12px 32px;
    font-size: 1rem;
    text-transform: uppercase;
    background-color: #3a3a3a;
    color: white;
    border-radius: 8px;
    position: relative;
    margin: auto;
}
.WorkPicture{
    justify-content: space-between;
    display: flex;
    transition: 0.8s;
    
}
.WorkPicture .IMAGE{
    padding-left: 5%;
    z-index: 6;
}
.WorkButtons .icons{
    
    z-index: 5;
}
/* .WorkPicture:hover{
    transform: scale(2);
    transition: 0.8s;
} */
.arrows{
    text-align:center;
 }

 
.WorkPictureZoom{
    transform: scale(1.5);
    transition: 0.8s;
    justify-content: space-between;
    display: flex;
}

.WorkPictureZoom .IMAGE img{
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 620px) {
    .WorkContainer{
        /* max-width: 100%; */
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
        text-align: center;
    }
    
    .WorkPictureZoom{
        transform: scale(1.05);
        transition: 0.8s;
        justify-content: space-between;
        display: flex;
    }
    .button2{
        margin: auto;
        padding: 3px 8px;
        font-size: 1rem;
        text-transform: uppercase;
        background-color: #3a3a3a;
        color: white;
        border-radius: 8px;
    }
}