.imageHome{
    width: 100%;
    height: 100vh;
    position: relative;
}
.introImage{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: cover;
    object-fit: cover;
}
.content p{
    color:rgb(0, 0, 0);
    text-align: center;
    display:block;
}
/*Image opacity*/
.imageHome::after{
    content:"";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #616161;
    opacity: 0.3;
}
/*Image size*/
.hero1{
    height: 100%;
    width: 100%;
}

/*Text in front of image adjustment*/
.hero1 .content{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hero1 .content h1{
    font-size:  4rem;
    padding: 0.6rem 0 1.5rem;

}

.hero1 .content p{
    font-size: 1.4rem;
    font-weight: 200;
    text-transform:  uppercase;
}

/*Button adjusment */
.content .btn{
    margin: 1rem 0.3rem;
}
.content .button1{
    margin: 1rem 0.3rem;
}


@media screen and (max-width:620px){
    .hero1 .content h1{
        font-size:  3rem; 
        width: 100%;
    }
    
    .hero1 .content p{
        font-size: 1.4rem;
    }
}