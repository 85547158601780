label{
    color: black;
    margin-bottom: 0.5rem;
}
form{
    display:flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 660px;

}

form input, form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: gray;
    border-color: gray;
    color: white;
}