.ProjectWork{
    width: 100%;
    height: 100%;
    padding: 6rem 1rem;
    background-color: rgb(117, 150, 179);
}
.EducationHeading{
    color: black;
    text-align: center;
    padding: 4rem 0 2rem 0;
}
/*Alligning cards*/
.cardcontainer{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.cards{
    border: 1px solid;
    color:rgb(0, 0, 0);
    padding: 1rem;
    text-align: center;
}
.cards:hover{
    background-color:  rgba(97, 97, 97, 0.85);
}

.GPA{
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    padding:1rem 0;
}

.cards a{
    font-size: 2rem;
    padding: 1rem;
    display:block;
    text-align: center;
    text-decoration: underline;
}
.cards a:hover{
    color: rgb(255, 230, 0);
}
.cards a:visited{
    color: green;
}
.AboutSection{
    font-size: 1rem;
    
}
.cards p{
    display: block;
    padding: 8px 0;

}

/* .cards .button1{
    display:block;
    display: 80%;
    margin: 2rem auto;
} */
.SchoolLogo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
}
@media screen and (max-width: 740px) {
    .cardcontainer{
        max-width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
        text-align: center;
    }
}